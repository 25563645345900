.ant-menu {
    margin-top: 5px;
}
.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu{
    vertical-align:  sub !important;
}
.ant-menu-submenu-active {
    color: #ffffff !important ;
}
.ant-menu-submenu-popup , .ant-menu-dark .ant-menu-sub{
    background: white !important;
    border-radius: 0 0 15px 0 !important;
    box-shadow: 1px 1px 3px #888;
}

.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item.ant-menu-item-selected > a {
    color:  rgba(73, 47, 146, 1) !important;
}

 .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background: unset !important;
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected > a {
    color: blue !important;
}
 .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active {
    border-bottom: 1px solid #4A2584 !important;
    background: rgba(73, 47, 146, 0.17) !important;
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item > a {
    color: black !important;
}
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title  {
    color : black !important;
}
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color : black !important;
}
/* .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title:hover {
    
    background: rgba(73, 47, 146, 0.17) !important;
} */
.ant-menu-submenu-popup.ant-menu-dark  .ant-menu-submenu-active {
    background: rgba(73, 47, 146, 0.17) !important;

}
.ant-menu-submenu .ant-menu-submenu-vertical .ant-menu-submenu-open .ant-menu-submenu-active .ant-menu-submenu-selected {
    background: rgba(73, 47, 146, 0.17) !important;
}
