.ant-btn-primary{
    background-color: #4a2584 !important;
}
.ck-editor__editable {
    min-height: 100px !important;
}

.ant-input-affix-wrapper .ant-input{
    border-radius: 0 !important;
    border-color: #c4c4c4 !important;
   
}

.ant-form-item-control{
    border-color: #c4c4c4 !important;
}